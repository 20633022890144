<template>
  <div class="container">
    <b-row class="justify-content-center">
      <b-col md="6" sm="8" class="p-0 m-0">
        <b-card no-body class="p-0 m-0">
          <b-card-body class="p-4">
            <b-form>
              <h1>회원 정보</h1>
              <b-input-group class="mt-4 mb-3">
                <b-input-group-prepend>
                  <b-input-group-text>
                    <i class="icon-user"></i>
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-form-input type="text" class="form-control" placeholder="이름" v-model="user.email" readonly />
              </b-input-group>

              <!-- <b-input-group class="mb-3">
                <b-input-group-prepend>
                  <b-input-group-text>@</b-input-group-text>
                </b-input-group-prepend>
                <b-form-input type="text" class="form-control" placeholder="아이디" v-model="user.email" readonly />
              </b-input-group> -->

              <b-input-group class="mt-4 mb-3">
                <b-input-group-prepend>
                  <b-input-group-text>
                    <i class="icon-user"></i>
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-form-select v-model="user.level" :options="levelOptions" />
              </b-input-group>
              <b-input-group class="mb-3">
                <b-input-group-prepend>
                  <b-input-group-text>
                    <i class="icon-lock"></i>
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-form-input
                  type="password"
                  class="form-control"
                  placeholder="비밀번호"
                  autocomplete="new-password"
                  v-model="user.password"
                />
              </b-input-group>
              <b-input-group class="mb-3">
                <b-input-group-prepend>
                  <b-input-group-text>
                    <i class="icon-tag"></i>
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-form-input type="text" class="form-control" placeholder="추천코드" v-model="user.recommendCode" />
              </b-input-group>
              <b-input-group class="mb-3" v-if="user.level >= 6">
                <b-input-group-prepend>
                  <b-input-group-text>
                    <i class="icon-tag"></i>
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-form-input type="text" class="form-control" placeholder="관리코드" v-model="user.manageCodes" />
              </b-input-group>
              <b-input-group class="mb-3" v-if="this.$store.state.user.level == 9">
                <b-input-group-prepend>
                  <b-input-group-text>
                    <i class="icon-calculator"></i>
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-form-input type="text" class="form-control" placeholder="보유금액" v-model="user.money" readonly />
                <b-button
                  v-show="this.$store.state.user.level == 9"
                  @click.prevent="plusAmount"
                  variant="success"
                  class="ml-1"
                >
                  충전
                </b-button>
                <b-button
                  v-show="this.$store.state.user.level == 9"
                  @click.prevent="minusAmount"
                  variant="warning"
                  class="ml-1"
                >
                  차감
                </b-button>
              </b-input-group>
              <b-input-group class="mb-3">
                <b-input-group-prepend>
                  <b-input-group-text>
                    <i class="icon-calendar"></i>
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-form-input type="text" class="form-control" placeholder="가입일" v-model="user.createdAt" readonly />
              </b-input-group>
              <b-input-group class="mb-3">
                <b-button @click.prevent="pushPayments" variant="dark" class="ml-2">충전내역 조회</b-button>
                <b-button @click.prevent="pushRequests" variant="dark" class="ml-2">발송내역 조회</b-button>
                <b-button @click.prevent="openNoteModal()" variant="warning" class="ml-2" style="color: white"
                  >쪽지</b-button
                >
              </b-input-group>
              <div class="btn-wrap">
                <b-button @click.prevent="$router.go(-1)" variant="secondary">이전</b-button>
                <b-button v-show="this.$store.state.user.level == 9" @click.prevent="updateUser" variant="primary"
                  >수정</b-button
                >
                <b-button v-show="this.$store.state.user.level == 9" @click.prevent="deleteUser" variant="danger"
                  >삭제</b-button
                >
              </div>
            </b-form>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-modal ref="noteModal" :title="user.username + '님에게 보내기'" centered hide-footer>
      <b-form-input type="text" class="form-control" placeholder="내용" v-model="noteBody" />

      <b-btn class="mt-3" ref="sendBtn" variant="primary" block @click="createNote">보내기</b-btn>
    </b-modal>
  </div>
</template>

<script>
import SmsService from '@/services/SmsService'
import UserService from '@/services/UserService'
import NoteService from '@/services/NoteService'

export default {
  name: 'User',
  created() {},
  mounted() {
    document.getElementsByClassName('app-body')[0].classList.remove('bg-white')
    this.getUser()
  },
  data() {
    return {
      user: {
        level: '1',
        username: '',
      },
      levelOptions: [
        {
          value: 1,
          text: '회원',
        },
        {
          value: 8,
          text: '부관리자',
        },
        {
          value: 9,
          text: '관리자',
        },
      ],
      useRefund: false,
      useLong: false,
      canDownload: false,
      noteBody: '',
    }
  },
  watch: {
    canDownload() {},
    useRefund() {},
  },
  methods: {
    async getUser() {
      const response = await UserService.getUser(this.$route.params.id)
      if (response.status == 200) {
        this.user = response.data.user
        this.canDownload = this.user.canDownload == 1
        this.useRefund = this.user.useRefund == 1
        this.useLong = this.user.useLong == 1
      }
    },
    async updateUser() {
      const response = await UserService.updateUser(this.user.id, {
        money: this.user.money,
        password: this.user.password,
        recommendCode: this.user.recommendCode,
        manageCodes: this.user.manageCodes,
        level: this.user.level,
        canDownload: this.canDownload ? 1 : 0,
        useRefund: this.useRefund ? 1 : 0,
        useLong: this.useLong ? 1 : 0,
      })

      if (response.status == 200) {
        alert('Success')
        this.$router.go(-1)
      }
    },
    async deleteUser() {
      if (!confirm('Are you sure you want to delete?')) return
      const response = await UserService.deleteUser(this.user.id)
      if (response.status == 200) {
        alert('Success')
        this.$router.go(-1)
      }
    },
    async plusAmount() {
      let changeAmount = prompt('충전할 발란스를 입력해주세요')
      if (!changeAmount) {
        return
      }
      const confirmString = `[${changeAmount}]을 [충전]하시겠습니까?`
      if (!confirm(confirmString)) {
        return
      }

      changeAmount = changeAmount.replace(/,/g, '')
      changeAmount = parseFloat(changeAmount)
      if (isNaN(changeAmount) || changeAmount < 0) {
        alert('유효한 값을 입력해주세요')
        return
      }

      const response = await UserService.changeUserAmount(this.user.id, changeAmount)
      if (response.status !== 200) {
        return
      }

      this.getUser()
    },
    async minusAmount() {
      let changeAmount = prompt('차감할 발란스를 입력해주세요')
      if (!changeAmount) {
        return
      }
      const confirmString = `[${changeAmount}]을 [차감]하시겠습니까?`
      if (!confirm(confirmString)) {
        return
      }

      changeAmount = changeAmount.replace(/,/g, '')
      changeAmount = parseFloat(changeAmount)
      if (isNaN(changeAmount) || changeAmount < 0) {
        alert('유효한 값을 입력해주세요')
        return
      }
      const response = await UserService.changeUserAmount(this.user.id, -changeAmount)
      if (response.status !== 200) {
        return
      }

      this.getUser()
    },
    async requestAllAttachments() {
      SmsService.getAllAttachment(this.$route.params.id).then(response => {
        const url = window.URL.createObjectURL(new Blob(['\ufeff', response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', this.user.email + '.csv')
        document.body.appendChild(link)
        link.click()
      })
    },
    pushPayments() {
      this.$router.push({
        path: '/payment/history',
        query: {
          userId: this.user.id,
        },
      })
    },
    pushRequests() {
      this.$router.push({
        path: '/SmsRequests',
        query: {
          userId: this.user.id,
        },
      })
    },
    async createNote() {
      try {
        await NoteService.create({ userId: this.user.id, body: this.noteBody })
        this.noteBody = ''
        this.$refs['noteModal'].hide()
        this.getStatus()
      } catch (e) {
        // Do nothing
      }
    },
    openNoteModal() {
      this.$refs['noteModal'].show()
    },
  },
}
</script>
<style>
.custom-control-label {
  margin-left: 20px;
}

.btn-wrap {
  display: flex;
  flex-flow: row;
}

.btn-wrap > button {
  margin-left: 10px;
  padding: 0;
  height: 40px;
  line-height: 40px;
  flex-grow: 1;
}

.btn-wrap > button:first-of-type {
  margin-left: 0;
}
</style>
