import Api from '@/services/Api'

export default {
  createSmsRequest(params) {
    return Api(undefined, { timeout: 150 * 1000 }).post('/api/smsRequests', params)
  },
  getRequests(params) {
    return Api().get('/api/smsRequests', {
      params: params,
    })
  },
  deleteSmsRequests(params) {
    return Api().delete('/api/smsRequests', { data: params })
  },
  getRequest(id) {
    return Api().get(`/api/smsRequests/${id}`)
  },
  getRequestUnits(requestId, params) {
    return Api().get(`/api/smsRequests/${requestId}/units`, {
      params: params,
    })
  },
  getRequestUnit(requestId, id) {
    return Api().get(`/api/smsRequests/${requestId}/units/${id}`)
  },
  getAttachment(requestId, params) {
    return Api().get(`/api/smsRequests/${requestId}/attachment`, {
      params: params,
    })
  },
  getAllAttachment(userId) {
    return Api().get(`/api/smsRequests/allAttachment/${userId}`)
  },
  cancelRequest(requestId) {
    return Api().put(`/api/smsRequests/${requestId}/cancelRequest`)
  },
  cancelReservation(requestId) {
    return Api().put(`/api/smsRequests/${requestId}/cancelReservation`)
  },
  resend(requestId) {
    return Api().post(`/api/smsRequests/${requestId}/resend`)
  },
}
